import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { List, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { IFormType, IFormDefinition, IValidationResult } from '@ngt/forms';
import { IPISignOff, IPISignOffBatch, IPISignOffDefinition } from '../../api/dtos';
import PISignOffSummaryListType, { IPISignOffSummaryFormTypeActionProps } from './PISignOffSummaryListType';
import PISignOffSummaryListQueries, { IPISignOffSummaryQueryActionProps } from './PISignOffSummaryListQueries';
import { BasicAlert, IPatient } from '@ngt/forms-trials';

interface IPISignOffSummaryListProps {
    patient?: IPatient;
    //reviewer?: IReviewer
    piSignOff?: IPISignOff;
    piSignOffBatch?: IPISignOffBatch;
    piSignOffDefinition?: IPISignOffDefinition;
    formValidations?: IValidationResult[];
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    actions?: Record<number, ComponentType<IPISignOffSummaryFormTypeActionProps>>;
    queryAction?:ComponentType<IPISignOffSummaryQueryActionProps>;
}

const useStyles = makeStyles((theme: Theme) => ({
    error: {
        padding: theme.spacing(2)
    },
}));


const PISignOffSummaryList: FunctionComponent<IPISignOffSummaryListProps> = ({
    piSignOff,
    piSignOffDefinition,
    formDefinitions,
    formTypes,
    formValidations,
    actions,
    patient,
    //reviewer,
    queryAction,
    piSignOffBatch
}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState<Record<number, boolean>>({})

    const combinedForms = useMemo(() => {
        return formValidations?.map(formValidation => {
            const formDefinition = formDefinitions?.find(d => d.id === formValidation.metadata.FormDefinitionId);

            const hasMultiple = (formValidations.filter(x => formDefinition?.id === x.metadata.FormDefinitionId)?.length ?? 0) > 1;

            return {
                formValidation,
                formDefinition: formDefinition!,
                hasMultiple
            }
        }).filter(x => !!x) ?? [];
    }, [formValidations, formDefinitions])

    const combinedTypes = useMemo(() => {
        return formTypes?.map(formType => {
            const forms = combinedForms?.filter(x => x.formDefinition?.type === formType.id) ?? [];

            if (forms.length === 0) {
                return null;
            }

            return {
                formType,
                forms: forms,
                action: actions?.[formType.id!]
            };
        }).filter(x => !!x) ?? [];

    }, [combinedForms, formTypes, actions]);

    useEffect(() => {
        setExpanded(currentExpanded => {
            const newExpanded: Record<number, boolean> = {}

            formTypes?.forEach(formType => {
                newExpanded[formType.id!] = currentExpanded[formType.id!] ?? true;
            })

            newExpanded[-1] = currentExpanded[-1] ?? true;

            return newExpanded;
        })
    }, [formTypes, setExpanded]);

    const toggleExpanded = useCallback((id: number) => {
        setExpanded(currentExpanded => ({
            ...currentExpanded,
            [id]: !currentExpanded[id]
        }))
    }, [setExpanded]);

    const noFormTypes = !combinedTypes?.length;

    if (noFormTypes) {
        return (
            <div className={classes.error}>
                <BasicAlert
                    title="No Reports"
                    description="This PI sign-off contains no reports."
                    icon={faExclamationTriangle}
                    severity="warning"
                />
            </div>
        )
    }

    return (
        <List>
            {
                combinedTypes.map(combinedType => {
                    if (!combinedType) {
                        return null;
                    }

                    return (
                        <PISignOffSummaryListType
                            key={combinedType.formType?.id}
                            patient={patient}
                            piSignOff={piSignOff}
                            piSignOffDefinition={piSignOffDefinition}
                            //reviewer={reviewer}
                            piSignOffBatch={piSignOffBatch}
                            expanded={expanded[combinedType.formType?.id!]}
                            toggleExpanded={toggleExpanded}
                            action={combinedType.action}
                            forms={combinedType.forms}
                            formType={combinedType.formType}
                        />
                    )
                })
            }
            <PISignOffSummaryListQueries
                patient={patient}
                piSignOff={piSignOff}
                piSignOffDefinition={piSignOffDefinition}
                //reviewer={reviewer}
                piSignOffBatch={piSignOffBatch}
                expanded={expanded[-1]}
                toggleExpanded={toggleExpanded}
                formTypes={formTypes!}
                forms={combinedForms}
                action={queryAction}
            />
        </List>
    );
};

export default PISignOffSummaryList;
