import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { Form, IFormType, IFormLabel, ILookup, FieldProvider, CheckboxGroup, Checkbox, GroupedField, SubmitButton, ILookupFilterProps, IValidationError, ValidationErrorType, FormsContext, useSnackbar, IFormDefinition, GroupedFields } from '@ngt/forms';
import { IPISignOffDefinition, PISignOffStatus, PISignOffPostUpdateSignOff, IPISignOff, IPISignOffBatch } from '../../api/dtos';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IFormSubmit, IFormSubmitFailed, IFormValidate, ValidateOn } from '@ngt/forms-core';
import { AlertTitle } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IInstitution, IPatient, BasicAlert } from '@ngt/forms-trials';
import usePISignOffDefinitions from '../../hooks/configuration/usePISignOffDefinitions';

interface IUpdatePISignOffDialogProps {
    piSignOffBatch: IPISignOffBatch;
    piSignOff: IPISignOff;
    piSignOffDefinition?: IPISignOffDefinition;
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    institution?: IInstitution;
    patient?: IPatient;
    open: boolean;
    onClose: () => void;
    onFormUpdated?: () => Promise<any>;
}

interface IInternalForm {
    singleDefinition?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0,

        '&:first-of-type': {
            padding: 0
        }
    },
    settingsContainer: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',

        '&.Mui-expanded': {
            margin: 0
        }
    },
    settingsSummary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    settingsTitle: {
        padding: theme.spacing(0)
    },
    settingsContent: {
        display: 'block',
        padding: 0
    }
}));


const labels: IFormLabel[] = [
    {
        name: 'data.piSignOffDefinitionId',
        label: 'Type',
        detailedLabel: 'Type'
    },
    {
        name: 'data.reviewerId',
        label: 'Assigned Reviewer',
        detailedLabel: 'Assigned Reviewer'
    },
    {
        name: 'options.formDefinitionIds',
        label: 'Included Reports',
        detailedLabel: 'Included Reports'
    },
    {
        name: 'options.includeUnchangedForms',
        label: 'Include Unchanged Forms',
        detailedLabel: 'Include Unchanged Forms'
    },
    {
        name: 'options.compareFormsAcrossPISignOffDefinitions',
        label: 'Compare Forms Across Review Types',
        detailedLabel: 'Compare Forms Across Review Types'
    }
];

const lookupFilters: Record<string, ILookupFilterProps> = {
    formDefinitionIds: {
        filterFunction: (formState, fieldName, lookup) => {

            if (!lookup) {
                return undefined;
            }

            return {
                ...lookup,
                items: lookup?.items?.filter(x => (x as any)?.parent === formState.value.data?.piSignOffDefinitionId)
            }

        },
        subscription: { value: true }
    }
}

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 8,
    xl: 8
};

const validate: IFormValidate<PISignOffPostUpdateSignOff, IValidationError> = async (formState, formActions) => {
    let errors: Record<string, IValidationError[]> = {};

    if (!formState.value?.options?.formDefinitionIds?.length) {
        errors['options.formDefinitionIds'] = [
            {
                code: 'CMR-001',
                message: 'At least one report must be selected.',
                detailedMessage: 'At least one report must be selected.',
                property: 'options.formDefinitionIds',
                type: ValidationErrorType.Normal
            }
        ];
    }

    return errors;
}

const UpdatePISignOffDialog: FunctionComponent<IUpdatePISignOffDialogProps> = ({
    piSignOffBatch,
    piSignOff,
    piSignOffDefinition,
    formTypes,
    formDefinitions,
    institution,
    patient,
    open,
    onClose,
    onFormUpdated
}) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const forms = useContext(FormsContext);
    const piSignOffContext = useContext(PISignOffExtensionContext);

    const { enqueueSnackbar } = useSnackbar();

    const lookups: ILookup[] = useMemo(() => {
        return [
            {
                propertyName: 'options.formDefinitionIds',
                items: piSignOffDefinition?.availableFormDefinitionIds?.map((formDefinitionId, i) => {
                    const formDefinition = formDefinitions?.find(x => x.id === formDefinitionId);

                    return {
                        id: formDefinitionId,
                        value: formDefinition?.name ?? formDefinitionId,
                        order: i,
                        parent: piSignOffDefinition.id
                    }
                }) ?? []
            }
        ] as ILookup[];
    }, [piSignOffDefinition, formDefinitions])

    const initialValues: PISignOffPostUpdateSignOff = useMemo(() => {

        return {
            piSignOffId: piSignOff?.id,
            metadata: {},
            options: {
                formDefinitionIds: piSignOffBatch?.formDefinitionIds ?? [],
                includeUnchangedForms: false,
                addNewForms: true,
                compareFormsAcrossPISignOffDefinitions: false,
                deleteOldForms: true
                
            }
        } as unknown as PISignOffPostUpdateSignOff

    }, [piSignOffDefinition, piSignOffBatch, piSignOff, patient]);

    const onSubmit: IFormSubmit<PISignOffPostUpdateSignOff, IValidationError> = useCallback(async (formState, formActions) => {
        if(!formState.value)
            return;

        try{
            const response = await forms.serviceStackClient.post(new PISignOffPostUpdateSignOff(formState.value!));
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        PI Sign-Off Updated
                    </AlertTitle>
                    The PI sign-off was successfully updated.
                </>,
                { variant: 'success' }
            );

            if (onFormUpdated) {
                await onFormUpdated();
            }

            if (onClose) {
                onClose();
            }
        }
        catch{

        }
    }, [forms, enqueueSnackbar, history, piSignOffContext, patient, onFormUpdated, onClose])


    const onSubmitFailed: IFormSubmitFailed<PISignOffPostUpdateSignOff, IValidationError> = useCallback(async (formState, formActions) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    PI Sign-Off Not Updated
                </AlertTitle>
                An error occurred while attempting to update the PI sign-off.
            </>,
            { variant: 'error-critical' }
        );
    }, [enqueueSnackbar]);


    const { data: piSignOffDefinitions, loading: piSignOffDefinitionsLoading } = usePISignOffDefinitions();

    const singleDefinition = (piSignOffDefinitions?.length ?? 0) <= 1;

    return (
        <Dialog open={open} onClose={onClose} fullWidth scroll="body" maxWidth="md">
            <DialogTitle>
                Update PI Sign-Off
            </DialogTitle>
            {
                piSignOffDefinitionsLoading && (
                    <CircularProgress color="primary" />
                )
            }
            {
                !piSignOffDefinitionsLoading && (
                    <Form
                        initialValues={initialValues}
                        labels={labels}
                        lookups={lookups}
                        onValidate={validate}
                        validateOn={ValidateOn.onChange}
                        onSubmit={onSubmit}
                        onSubmitFailed={onSubmitFailed}
                    >
                        <DialogContent className={classes.content} dividers>
                            <BasicAlert
                                title="Please Note"
                                description={
                                    <>
                                        <p>Are you sure you wish to update the reports?</p>
                                    </>
                                }
                                icon={faInfoCircle}
                                severity="info"
                            />
                            {/* <InternalForm singleDefinition={singleDefinition} /> */}
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <SubmitButton color="primary">
                                Ok
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                )
            }
        </Dialog>
    );
};

const InternalForm: FunctionComponent<IInternalForm> = ({
    singleDefinition
}) => {
    const classes = useStyles();

    return (
        <>
            <GroupedFields>
                <Accordion className={classes.settingsContainer} elevation={0} square>
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.settingsSummary}
                    >
                        <Typography variant="h2" className={classes.settingsTitle}>
                            Advanced Settings
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.settingsContent}>
                        <FieldProvider name="options" autoRegister={false}>
                            <GroupedField
                                name="formDefinitionIds"
                                component={CheckboxGroup}
                                {...groupStyleProps}
                            />
                            <GroupedField
                                name="includeUnchangedForms"
                                component={Checkbox}
                                {...groupStyleProps}
                            />
                            {
                                !singleDefinition && (
                                    <GroupedField
                                        name="compareFormsAcrossPISignOffDefinitions"
                                        component={Checkbox}
                                        {...groupStyleProps}
                                    />
                                )
                            }
                        </FieldProvider>
                    </AccordionDetails>
                </Accordion>
            </GroupedFields>
        </>
    );
};

export default UpdatePISignOffDialog;
