import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, TextField, Theme, FormControl, InputLabel,Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, IFormType, IFormLabel, ILookup, FieldProvider, CheckboxGroup, Checkbox, GroupedField, SubmitButton, ILookupFilterProps, IValidationError, ValidationErrorType, FormsContext, useSnackbar, IFormDefinition, GroupedFields, ProgressButton } from '@ngt/forms';
import { IPISignOffDefinition, PISignOffStatus, PISignOffPostUpdateSignOff, IPISignOff, IPISignOffBatch, IInvestigator } from '../../api/dtos';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IFormSubmit, IFormSubmitFailed, IFormValidate, ValidateOn } from '@ngt/forms-core';
import { AlertTitle, Autocomplete } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IInstitution, IPatient, BasicAlert, InstitutionContext } from '@ngt/forms-trials';
import usePISignOffDefinitions from '../../hooks/configuration/usePISignOffDefinitions';

import PISignOffContext from '../../contexts/data/PISignOffContext';
import InvestigatorsContext from '../../contexts/data/InvestigatorsContext';

interface IUpdatePISignOffDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit?: (investigatorId?: number) => Promise<any>;
}

interface IInternalForm {
    singleDefinition?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0,

        '&:first-of-type': {
            padding: 0
        }
    },
    settingsContainer: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',

        '&.Mui-expanded': {
            margin: 0
        }
    },
    settingsSummary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    settingsTitle: {
        padding: theme.spacing(0)
    },
    settingsContent: {
        display: 'block',
        padding: 0
    },
    field: {
        padding: theme.spacing(3)
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 8,
    xl: 8
};

const AssignPISignOffDialog: FunctionComponent<IUpdatePISignOffDialogProps> = ({
    open,
    onClose,
    onSubmit
}) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const forms = useContext(FormsContext);
    const { data: piSignOff, save: savePISignOff } = useContext(PISignOffContext);
    const { data: investigators } = useContext(InvestigatorsContext);
    const { data: institution } = useContext(InstitutionContext);
    const piSignOffContext = useContext(PISignOffExtensionContext);

    const [investigator, setInvestigator] = useState<IInvestigator | null>(null);
    const [processing, setProcessing] = useState(false);

    const filteredInvestigators = useMemo(() => {
        if (!investigators || !institution) {
            return undefined;
        }

        return investigators?.filter(x => 
            (x.institutions && institution.id && x.institutions[institution.id]?.some(y => y.active === true)) || 
            (x.coordinatingGroups && institution.coordinatingGroupId && x.coordinatingGroups[institution.coordinatingGroupId]?.some(y => y.active === true)) || 
            (x.masterGroups && institution.masterGroupId && x.masterGroups[institution.masterGroupId]?.some(y => y.active === true)) || 
            (x.onTrial && x.onTrial.some(y => y.active == true))
        );
    }, [investigators, institution])

    useEffect(() => {
        setInvestigator(investigators?.find(x => x.id === piSignOff?.investigatorId) ?? null);
    }, [investigators, piSignOff?.investigatorId, setInvestigator])

    const { enqueueSnackbar } = useSnackbar();

    const onFormSubmit = useCallback(async () => {
        setProcessing(true);

        try {
            const response = await savePISignOff({...piSignOff as any, investigatorId: investigator?.id });
    
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        PI Sign-Off Assigned
                    </AlertTitle>
                    The PI sign-off was successfully assigned.
                </>,
                { variant: 'success' }
            );
    
            if (onSubmit) {
                await onSubmit();
            }
    
            if (onClose) {
                onClose();
            }
        }
        catch {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        PI Sign-Off Not Assigned
                    </AlertTitle>
                    An error occurred while attempting to assign the PI sign-off.
                </>,
                { variant: 'error-critical' }
            );
        }

        setProcessing(false);

    }, [forms, enqueueSnackbar, history, piSignOffContext, onSubmit, onClose, savePISignOff, piSignOff, setProcessing, investigator]);

    const onChange = useCallback((event: any) => {
        setInvestigator(investigators?.find(x => x.name === event.target.value) ?? null);
    }, [setInvestigator]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth scroll="body" maxWidth="md">
            <DialogTitle>
                Assign PI Sign-Off
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <BasicAlert
                    title="Please Note"
                    description={
                        <>
                            <p>Only the assigned investigator will be able to complete the PI sign-off.</p>
                        </>
                    }
                    icon={faInfoCircle}
                    severity="info"
                />
                <div className={classes.field}>
                        <FormControl
                            fullWidth
                        >
                            <InputLabel id="investigator-label">Investigator</InputLabel>
                            <Select
                                labelId="investigator-label"
                                id="investigator"
                                label="Investigator"
                                value={investigator?.name}
                                onChange={(event) => {onChange(event); console.log(investigator)}}
                                fullWidth
                            >
                                {
                                    filteredInvestigators?.map((investigator) => (
                                        <MenuItem key={investigator.id} value={investigator.name}>
                                            {investigator.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    {/* <Autocomplete
                        id="investigatorId"
                        options={filteredInvestigators ?? []}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                        renderInput={(params) => <TextField {...params} label="Investigator" />}
                        onChange={onChange}
                        value={investigator}
                    /> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <ProgressButton color="primary" onClick={onFormSubmit} loading={processing}>
                    Ok
                </ProgressButton>
            </DialogActions>
        </Dialog>
    );
};

export default AssignPISignOffDialog;
