import React, { ChangeEvent, FunctionComponent, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { CollapsibleTable, useUser, useFormDefinitionByCode, useFormDefinitionById, useSnackbar } from '@ngt/forms';
import PISignOffExtensionContext from '../contexts/PISignOffExtensionContext';
import { AlertTitle, Box, Button, Chip, Grid, TextField, Theme, Typography } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import useInvestigators from '../hooks/data/useInvestigators';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { convertPISignOffStatusToString } from '../utilities/PISignOffStatus';
import { DateTime } from 'luxon';
import { CoordinatingGroupsContext, CountriesContext, InstitutionsContext, IPatient, MasterGroupsContext, PatientsContext, useCoordinatingGroups, useCountries, useInstitutions, useMasterGroups, usePatients } from '@ngt/forms-trials';
import { IPISignOff, IPISignOffPatient, PISignOffPermission, PISignOffStatus } from '../api/dtos';
//import usePatientPISignOffs from '../hooks/data/usePatientPISignOffs';
import usePISignOffBatchById from '../hooks/data/usePISignOffBatchById';
import useContextPermissions from '../hooks/utility/useContextPermissions';
import usePISignOffsByPISignOffBatchId from '../hooks/data/usePISignOffsByPISignOffBatchId';
import PISignOffDefinitionsContext from '../contexts/configuration/PISignOffDefinitionsContext';
import InvestigatorsContext from '../contexts/data/InvestigatorsContext';
import ContextPISignOffBatchBreadcrumbs from '../components/breadcrumbs/ContextPISignOffBatchBreadcrumbs';
import PISignOffBatchContext from '../contexts/data/PISignOffBatchContext';
import PISignOffsContext from '../contexts/data/PISignOffsContext';
import PISignOffDefinitionContext from '../contexts/configuration/PISignOffDefinitionContext';
import { makeStyles } from '@mui/styles';
import CompletePISignOffBatchDialog from '../components/dialogs/CompletePISignOffBatchDialog';
import CancelPISignOffBatchDialog from '../components/dialogs/CancelPISignOffBatchDialog';

interface IPISignOffBatchProps {
    rowsPerPageOptions?: number[];
    initialPageSize?: number;
    instructions?: ReactNode;
}

interface IPatientPISignOffBatchFilter {
    masterGroupId?: number;
    coordinatingGroupId?: number;
    countryId?: number;
    institutionId?: number;
    investigatorId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    requiresAttention: {
        color: theme.palette.warning.main
    },
    cell: {
        cursor: 'pointer',

        '&:focus': {
            outline: 'none !important'
        }
    }
}));

const xs = 12;
const sm = 12;
const md = 6;
const lg = 4;
const xl = 3;



const PISignOffBatch: FunctionComponent<IPISignOffBatchProps> = ({
    rowsPerPageOptions,
    initialPageSize,
    instructions,

    ...routeProps
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const filter: IPatientPISignOffBatchFilter = useMemo(() => {
        const f = parse(location.search, { ignoreQueryPrefix: true }) as any;

        try {
            return {
                masterGroupId: f.masterGroupId ? parseInt(f.masterGroupId, 10) : undefined,
                coordinatingGroupId: f.coordinatingGroupId ? parseInt(f.coordinatingGroupId, 10) : undefined,
                countryId: f.countryId ? parseInt(f.countryId, 10) : undefined,
                institutionId: f.institutionId ? parseInt(f.institutionId, 10) : undefined,
                investigatorId: f.investigatorId ? parseInt(f.investigatorId, 10) : undefined
            }
        }
        catch {
            return {};
        }
    }, [location.search]);


    const { data: [canViewAllPatients, canCreatePISignOff] } = useContextPermissions([PISignOffPermission.ViewAllPatients, PISignOffPermission.CreatePISignOff]);
    
    const { routeParameters, patientPISignOffColumnsFn, createPISignOffRouteFn, createPISignOffBatchRouteFn, createPISignOffFormRouteFn } = useContext(PISignOffExtensionContext);
    const routeParams = useParams<Record<string, string>>();
    const piSignOffBatchId = routeParams[routeParameters.piSignOffBatchId];

    const user = useUser()
    const { data: countries } = useContext(CountriesContext);
    const { data: masterGroups } = useContext(MasterGroupsContext);
    const { data: coordinatingGroups } = useContext(CoordinatingGroupsContext);
    const { data: institutions } = useContext(InstitutionsContext);
    const { data: investigators } = useContext(InvestigatorsContext);
    const { data: patients } = useContext(PatientsContext);
    const { data: piSignOffBatch, loading: piSignOffBatchLoading, save: savePiSignOffBatch } = useContext(PISignOffBatchContext);
    const { data: piSignOffDefinition } = useContext(PISignOffDefinitionContext);
    const { data: piSignOffs, mutate: mutatePiSignOffs } = useContext(PISignOffsContext);

    const institutionToUse = useMemo(() => {
        if (patients) {
            const institutionIds = Array.from(new Set(patients.map((p: any) => p.institutionId)));

            return institutions?.filter(i => institutionIds.find(id => id === i.id));
        }
        
        return [];
    }, [institutions, patients]);

    const filteredInvestigators = useMemo(() => {
        if (!piSignOffs || !investigators) {
            return undefined;
        }

        return investigators.filter(x => piSignOffs.some((y: any) => x.id == y.investigatorId));
    }, [piSignOffs, investigators]);

    const onPatientPISignOffClick = useCallback((params: GridRowParams) => {
        if (patients) {
            const patient = patients.find(p => p.id === params.row.patientId);
            navigate(createPISignOffRouteFn(piSignOffBatch!, piSignOffDefinition!, patient!, params.row as IPISignOff));
        }
    }, [history, createPISignOffRouteFn, patients, piSignOffBatch, piSignOffDefinition]);

    const patientPISignOffColumns: GridColDef[] = useMemo(() => {

        if (!patientPISignOffColumnsFn) {
            return [
                {
                    field: 'patientId',
                    headerName: 'Paticipant',
                    valueGetter: params => patients?.find(p => p.id === params.row.patientId)?.studyNumber,
                    width: 150
                },
                //{
                //    field: 'treatmentArm',
                //    headerName: 'Treatment Arm',
                //    valueGetter: params => patients?.find(p => p.id === params.row.patientId)?.treatmentArm,
                //    width: 180,
                //    hide: true
                //},
                //{
                //    field: 'country',
                //    headerName: 'Country',
                //    valueGetter: params => countries?.find(c => c.id === institutions?.find(i => i.id === params.row.institutionId)?.countryId)?.name,
                //    width: 200,
                //    hide: true
                //},
                {
                    field: 'institutionCode',
                    headerName: 'Institution',
                    //renderHeader: params => <><FontAwesomeIcon icon={faBuilding} />&nbsp;Code</>,
                    valueGetter: params => institutions?.find(i => i.id === patients?.find(p => p.id === params.row.patientId)?.institutionId)?.code,
                    renderCell: params => {
                        if (!params.value) {
                            return <div />;
                        }

                        return (
                            <Chip
                                label={params.value}
                                size="small"
                                color="secondary"
                            />
                        )
                    },
                    width: 150
                },
                {
                    field: 'institutionName',
                    headerName: 'Institution Name',
                    valueGetter: params => institutions?.find(i => i.id === patients?.find(p => p.id === params.row.patientId)?.institutionId)?.name,
                    flex: 1
                },
                //{
                //    field: 'enteredDate',
                //    headerName: 'Randomisation Date',
                //    valueGetter: params => patients?.find(p => p.id === params.row.patientId)?.enteredDate ? DateTime.fromISO(patients.find(p => p.id === params.row.patientId)!!.enteredDate!!).toFormat('dd/MM/yyyy') : undefined,
                //    width: 210
                //},
                {
                    field: 'dataDate',
                    headerName: 'Data as of ',
                    valueGetter: params => params.row.dataDate ? DateTime.fromISO(params.row.dataDate).toFormat('dd/MM/yyyy') : undefined,
                    width: 150
                },
                {
                   field: 'investigatorId',
                   headerName: 'Investigator',
                   valueGetter: params => {
                    const investigator = investigators?.find(x => x.id === params.row.investigatorId);

                    return investigator?.name;
                   },
                   width: 200
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    valueGetter: params => {
                        if (params.row.status === PISignOffStatus.Complete) {
                            return `${convertPISignOffStatusToString(params.row.status)} (${DateTime.fromISO(params.row.completeDate).toFormat('dd/MM/yyyy')})`
                        }

                        return convertPISignOffStatusToString(params.row.status);
                    },
                    width: 200
                },
                //{
                //    field: 'requiresAttention',
                //    headerName: 'Requires Attention',
                //    renderHeader: () => <div></div>,
                //    valueGetter: params => {
                //        if (!params.row.status) {
                //            return 1;
                //        }

                //        return params.row.requiresAttention?.reduce((a: number, b: number) => {
                //            const reviewer = reviewers?.find(x => x.id === b);

                //            if (!reviewer) {
                //                return a;
                //            }

                //            if (reviewer.usernames?.includes(user?.userName!)) {
                //                return 1;
                //            }

                //            return a;
                //        }, 0)
                //    },
                //    renderCell: params => {
                //        if (!params.value || params.value === 0) {
                //            return <div />;
                //        }

                //        return (
                //            <FontAwesomeIcon
                //                icon={faExclamationTriangle}
                //                size="lg"
                //                className={classes.requiresAttention}
                //            />
                //        )
                //    },
                //    align: 'center',
                //    width: 100
                //}
            ]
        }

        return patientPISignOffColumnsFn(user, countries ?? [], institutions ?? [], investigators ?? []) ?? [];
    }, [user, countries, institutions, patients, investigators, patientPISignOffColumnsFn, classes])


    const selectedMasterGroup = useMemo(() => {
        return masterGroups?.find(x => x.id === filter.masterGroupId) ?? null;
    }, [filter.masterGroupId, masterGroups]);

    const selectedCoordinatingGroup = useMemo(() => {
        return coordinatingGroups?.find(x => x.id === filter.coordinatingGroupId) ?? null;
    }, [filter.coordinatingGroupId, coordinatingGroups]);

    const selectedCountry = useMemo(() => {
        return countries?.find(x => x.id === filter.countryId) ?? null;
    }, [filter.countryId, countries]);

    const selectedInstitution = useMemo(() => {
        return institutions?.find(x => x.id === filter.institutionId) ?? null;
    }, [filter.institutionId, institutions]);

    const selectedInvestigator = useMemo(() => {
        return investigators?.find(x => x.id === filter.investigatorId) ?? null;
    }, [filter.investigatorId, investigators]);

    const onMasterGroupFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            masterGroupId: value?.id
        });

        if (piSignOffBatch) {
            navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition!, search));
        }
    }, [filter, history, createPISignOffBatchRouteFn, piSignOffBatch, piSignOffDefinition]);

    const onCoordinatingGroupFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            coordinatingGroupId: value?.id
        });

        if (piSignOffBatch) {
            navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition!, search));
        }
    }, [filter, history, createPISignOffBatchRouteFn, piSignOffBatch, piSignOffDefinition]);

    const onCountryFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            countryId: value?.id
        });

        if (piSignOffBatch) {
            navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition!, search));
        }
    }, [filter, history, createPISignOffBatchRouteFn, piSignOffBatch, piSignOffDefinition]);

    const onInstitutionFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            institutionId: value?.id
        });

        if (piSignOffBatch) {
            navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition!, search));
        }
    }, [filter, history, createPISignOffBatchRouteFn, piSignOffBatch, piSignOffDefinition]);

    const onReviewerFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            investigatorId: value?.id
        });

        if (piSignOffBatch) {
            navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition!, search));
        }
    }, [filter, history, createPISignOffBatchRouteFn, piSignOffBatch, piSignOffDefinition]);

    const filteredPISignOffs = useMemo(() => {
       if (!filter.masterGroupId &&
           !filter.coordinatingGroupId &&
           !filter.countryId &&
           !filter.institutionId &&
           !filter.investigatorId) {
           return piSignOffs?.slice()?.sort((a, b) => parseInt(patients?.find(p => p.id === a.patientId)?.studyNumber!!) - parseInt(patients?.find(p => p.id === b.patientId)?.studyNumber!!));
       }

       return piSignOffs?.filter(piSignOff => {

           const patient = patients?.find(p => p.id === piSignOff.patientId)

           if (filter.masterGroupId ||
               filter.coordinatingGroupId ||
               filter.countryId) {
               const institution = institutions?.find(i => i.id === patient?.institutionId);

               if (filter.masterGroupId && institution?.masterGroupId !== filter.masterGroupId) {
                   return false;
               }

               if (filter.coordinatingGroupId && institution?.coordinatingGroupId !== filter.coordinatingGroupId) {
                   return false;
               }

               if (filter.countryId && institution?.countryId !== filter.countryId) {
                   return false;
               }

           }

           if (filter.institutionId && patient?.institutionId !== filter.institutionId) {
               return false;
           }

           if (filter.investigatorId && piSignOff?.investigatorId !== filter.investigatorId) {
               return false;
           }

           return true;
       })?.slice()?.sort((a, b) => parseInt(patients?.find(p => p.id === a.patientId)?.studyNumber!!) - parseInt(patients?.find(p => p.id === b.patientId)?.studyNumber!!)) ?? [];
    }, [piSignOffs, institutions, filter, patients])

    const getClassName = useCallback(() => {
        return classes.cell
    }, [classes.cell]);
    
    const [closeOpen, setCloseOpen] = useState(false);
    const [completeOpen, setCompleteOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    
    const { enqueueSnackbar } = useSnackbar();

    const onCloseDialogOpen = useCallback(() => {
        setCloseOpen(true);
    }, [setCloseOpen]);

    const onCloseDialogClose = useCallback(() => {
        setCloseOpen(false);
    }, [setCloseOpen]);

    const onCompleteDialogOpen = useCallback(() => {
        setCompleteOpen(true);
    }, [setCompleteOpen]);

    const onCompleteDialogClose = useCallback(() => {
        setCompleteOpen(false);
    }, [setCompleteOpen]);

    const onFormMarkedComplete = useCallback(async () => {
        setProcessing(true);

        if (piSignOffBatch) {
            try {
                await savePiSignOffBatch({ ...piSignOffBatch, status: PISignOffStatus.Complete });
                await mutatePiSignOffs();

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Saved
                        </AlertTitle>
                        The PI sign-off has been successfully completed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the PI sign-off.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [piSignOffBatch, savePiSignOffBatch, setProcessing, enqueueSnackbar, mutatePiSignOffs]);


    const onFormMarkedClosed = useCallback(async () => {
        setProcessing(true);

        if (piSignOffBatch) {
            try {
                await savePiSignOffBatch({ ...piSignOffBatch, status: PISignOffStatus.Cancelled });
                await mutatePiSignOffs();

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Saved
                        </AlertTitle>
                        The PI sign-off has been successfully closed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the PI sign-off.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [piSignOffBatch, savePiSignOffBatch, setProcessing, enqueueSnackbar, mutatePiSignOffs]);


    return (
        <>
            <ContextPISignOffBatchBreadcrumbs />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h1" className={classes.title}>
                    Participants
                </Typography>
                {
                    canCreatePISignOff && !piSignOffBatchLoading && piSignOffBatch?.status !== PISignOffStatus.Complete && piSignOffBatch?.status !== PISignOffStatus.Cancelled && (
                        <Box
                            sx={{ml: 'auto'}}
                        >
                            <Button onClick={onCompleteDialogOpen} disabled={processing} variant="contained" color="primary">
                                Complete
                            </Button>
                            <Button sx={{ ml: 2, mr: 2}} onClick={onCloseDialogOpen} disabled={processing} variant="contained" color="primary">
                                Close
                            </Button>
                        </Box>
                    )
                }
            </Box>
            <CompletePISignOffBatchDialog
                onClose={onCompleteDialogClose}
                onSubmit={onFormMarkedComplete}
                pending={processing}
                piSignOffBatch={piSignOffBatch!}
                open={completeOpen}
            />
            <CancelPISignOffBatchDialog
                onClose={onCloseDialogClose}
                onSubmit={onFormMarkedClosed}
                pending={processing}
                piSignOffBatch={piSignOffBatch!}
                open={closeOpen}
            />
            <Typography className={classes.title}>
                Select the participant to review by clicking on the participant's table row
            </Typography>
            {
                canViewAllPatients && (
                    <div className={classes.selectors}>
                        <Grid container spacing={2}>
                            {
                                !!masterGroups?.length && (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        <Autocomplete
                                            id="masterGroupId"
                                            options={masterGroups ?? []}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Master Group" />}
                                            onChange={onMasterGroupFilterChange}
                                            value={selectedMasterGroup}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                !!coordinatingGroups?.length && (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        <Autocomplete
                                            id="coordinatingGroupId"
                                            options={coordinatingGroups ?? []}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Coordinating Group" />}
                                            onChange={onCoordinatingGroupFilterChange}
                                            value={selectedCoordinatingGroup}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                !!countries?.length && (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        <Autocomplete
                                            id="countryId"
                                            options={countries ?? []}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Country" />}
                                            onChange={onCountryFilterChange}
                                            value={selectedCountry}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                !!institutions?.length && (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        <Autocomplete
                                            id="institutionId"
                                            options={institutionToUse ?? []}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Institution" />}
                                            onChange={onInstitutionFilterChange}
                                            value={selectedInstitution}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                !!investigators?.length && (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        <Autocomplete
                                            id="investigatorId"
                                            options={filteredInvestigators ?? []}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Investigator" />}
                                            onChange={onReviewerFilterChange}
                                            value={selectedInvestigator}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>
                )
            }
            <CollapsibleTable
                title="Participants"
                entityName="Participant"
                loading={false}
                rows={filteredPISignOffs ?? []}
                columns={patientPISignOffColumns as any}
                autoHeight
                rowsPerPageOptions={rowsPerPageOptions}
                initialPageSize={initialPageSize}
                hideFooter={!rowsPerPageOptions}
                onRowClick={onPatientPISignOffClick}
                disableColumnFilter
                getCellClassName={getClassName}
            />

        </>
    );
};

export default PISignOffBatch;

